import { usePageLeave } from "@vueuse/core";
import { useCookies } from "@vueuse/integrations/useCookies";

export const useMyPage = ({ display } = {}) => {
  const cookies = useCookies(["zweikern_page_exit"]);

  const isLeft = usePageLeave();

  const dialog = ref({
    show: false,
    bind: {
      maxWidth: 450,
      persistent: false,
      scrollable: true,
    },
    events: {
      "click:outside": () => {
        dialog.value.show = false;
      },
    },
  });

  if (display?.mdAndDown?.value) {
    useUserPageInteraction(() => {
      dialog.value.show = true;
    });
  }

  function handleBeforeUnload() {
    if (!cookies.get("zweikern_page_exit")) {
      dialog.value.show = true;
      cookies.set("zweikern_page_exit", true, {
        expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
        path: "/",
      });
    }
  }

  function watchPageLeave(callback: () => void) {
    watchEffect(() => {
      if (isLeft.value) {
        callback();
        handleBeforeUnload();
      }
    });
  }

  return {
    cookie: {
      get: () => cookies.get("zweikern_page_exit"),
      set: (value: any, options: object = {}) => {
        options = {
          expires: new Date(
            Date.now() + (options?.expires || 7) * 24 * 60 * 60 * 1000,
          ), // x Tage in der Zukunft
          path: "/", // Stellen Sie sicher, dass der Pfad übereinstimmt
        };
        cookies.set("zweikern_page_exit", value, options);
      },
    },
    watchPageLeave,
    dialog,
  };
};
