export default defineNuxtRouteMiddleware(async (to, from) => {
  const token = to?.query?.token;
  const redirectCookie = useCookie("zweikern_token_redirect");
  const i18nCookie = useCookie("zweikern_i18n_redirected");
  const { cookie } = useMyPage();

  if (token) {
    const { data } = await useMailchimp().verifySubscription(token);
    if (data?.status === "subscribed") {
      cookie.set("subscribed", { expires: 365 });

      if (redirectCookie?.value) {
        return decodeURIComponent(
          `/${i18nCookie?.value !== "de" ? "en/" : ""}${redirectCookie?.value}`,
        );
      } else {
        return "/?subscribed=true";
      }
    }
  }
});
