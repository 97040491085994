export const useMailchimp = () => {
  const config = useRuntimeConfig();
  const { $i18n } = useNuxtApp();

  async function callApi(method: String, params = {}) {
    try {
      const { data, error } = await useFetch(
        () => `${config.public.zweikernApiUrl}/newsletter/subscribe`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            method,
            ...params,
            language: $i18n.locale.value,
          }),
        },
      );

      if (error.value) {
        throw new Error(error.value.message || "Network response was not ok");
      }

      return { data: data.value?.data, error };
    } catch (error) {
      return { error };
    }
  }

  async function callPing() {
    return await callApi("ping.get");
  }

  async function subscribeUser(email) {
    return await callApi("lists.addListMember", { email });
  }

  async function verifySubscription(token: string) {
    try {
      const { data, error } = await useFetch(
        () => `${config.public.zweikernApiUrl}/newsletter/verify_subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token,
          }),
        },
      );

      if (error.value) {
        throw new Error(error.value.message || "Network response was not ok");
      } else {
        const cookie = useCookie("zweikern_newsletter", {
          expires: new Date(new Date().setDate(new Date().getDate() + 365)),
        });
        cookie.value = "subscribed";
      }

      return { data: data.value?.data, error };
    } catch (error) {
      return { error };
    }
  }

  async function getListMembersInfo() {
    return await callApi("lists.getListMembersInfo");
  }

  return {
    callPing,
    subscribeUser,
    getListMembersInfo,
    verifySubscription,
  };
};
